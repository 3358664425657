<template>
  <nav
    class="navbar navbar-expand navbar-light fixed-top d-none d-md-flex"
    id="topbar"
  >
    <div class="container-fluid">
      <router-link tag="a" :to="$paths.home" class="navbar-brand">
        <img
          class="navbar-brand-img"
          src="@/assets/img/logo.png"
          alt="Diag Backoffice"
          width="122"
          height="40"
        />
      </router-link>

      <nav aria-label="breadcrumb" class="me-auto ms-4">
        <ol class="breadcrumb">
          <router-link
            v-if="link"
            tag="li"
            :to="link"
            class="breadcrumb-item d-flex align-items-center text-subtitle"
          >
            <a class="text-dark">{{ linkName }}</a>
          </router-link>
          <router-link
            v-if="sublink"
            tag="li"
            :to="sublink"
            class="breadcrumb-item d-flex align-items-center text-subtitle"
          >
            <a class="text-dark">{{ sublinkName }}</a>
          </router-link>
          <li
            v-if="link || sublink"
            class="breadcrumb-item active d-flex align-items-center"
            aria-current="page"
          >
            {{ current }}
          </li>
          <li
            v-else
            class="breadcrumb-item active d-flex align-items-center text-subtitle text-dark"
            aria-current="page"
          >
            {{ current }}
          </li>
        </ol>
      </nav>

      <div class="navbar-user" v-if="userSession">
        <router-link
          class="text-dark50 me-4"
          tag="a"
          :to="$paths.notifications"
        >
          <div>
            <i class="fa-solid fa-bell text-header"></i>
            <i
              class="fa-solid fa-circle text-danger notification-badge"
              v-if="unseenNotifications"
            ></i>
          </div>
        </router-link>
        <div
          class="dropdown cursor-pointer"
          @mouseover="avatarOver"
          @mouseleave="avatarLeave"
        >
          <span class="dropdown-toggle d-flex align-items-center">
            <i class="fa-solid fa-user-circle display-4 text-dark50 me-2"></i>
            <span class="text-subtitle">{{ userSession?.name }}</span>
            <small>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </small>
          </span>
          <div class="dropdown-menu dropdown-menu-end" id="navbar_dropdown">
            <router-link
              class="dropdown-item fw-bold"
              tag="a"
              :to="$paths.myAccount"
            >
              Minha conta
            </router-link>
            <hr class="dropdown-divider py-0" />
            <router-link
              class="dropdown-item fw-bold"
              tag="a"
              :to="$paths.myPlan"
            >
              Meu plano
            </router-link>
            <hr class="dropdown-divider py-0" />
            <span
              @click="logout"
              class="dropdown-item text-danger fw-bold cursor-pointer"
              >Sair</span
            >
          </div>
        </div>
      </div>
      <!-- <div v-else>
        <a @click.prevent="goToApp" class="text-primary text-subtitle">
          <i class="fas fa-arrow-left"></i>
          Voltar para o app
        </a>
      </div> -->
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
export default {
  name: "NavBar",
  mounted() {
    if (!this.userSession || this.$route.query.appWebView) {
      return;
    }
    this.getUnseenNotifications();
  },
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String,
  },
  data() {
    return {
      unseenNotifications: false,
    };
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  methods: {
    async getUnseenNotifications() {
      try {
        const res = await this.$userService.listUnseenNotifications();
        this.unseenNotifications = res.exist;
      } catch (error) {
        console.log(error);
      }
    },
    async logout() {
      try {
        // await this.$userService.logout();
        this.$store.commit("clearSession");
      } catch (error) {
        console.log("ERRO NO LOGOUT", error);
      } finally {
        this.$router.push(this.$paths.login);
      }
    },
    avatarOver() {
      $("#navbar_dropdown").addClass("show");
      $("#navbar_dropdown").attr("data-bs-popper", true);
    },
    avatarLeave() {
      $("#navbar_dropdown").removeClass("show");
      $("#navbar_dropdown").removeAttr("data-bs-popper");
    },
  },
};
</script>

<style scoped>
.notification-badge {
  position: relative;
  font-size: 8px;
  bottom: 10px;
  right: 5px;
}
</style>
